@import "~ng-pick-datetime/assets/style/picker.min.css";
@import '~@ng-select/ng-select/themes/default.theme.css';
//@import '@angular/material/prebuilt-themes/indigo-pink.css';

label { 
  color: black !important;
  font-weight: 500 !important;
}

input {
  color: black !important;
  font-weight: 500 !important;
}

select {
  color: black !important;
  font-weight: 500 !important;
}

mat-radio-group {
  color: black !important;
  font-weight: 500 !important;
}

.li-styl {
  cursor: pointer;
  //background-color: #39f;
  font-weight: 600;
  color: black;
  border-bottom: 2px solid #39f; 
}

.drop-down-btn {
  --cui-dropdown-min-width: 8rem !important;
  --cui-dropdown-padding-y: 0.1rem !important;
}

.input-with-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.calendar-icon {
  position: absolute;
  font-size: 21px;
  top: 0%;
  right: 5px; 
}

.sidebar-nav .nav-link.active {
  background-color: #3366CC !important;
}

.generate-bill.mat-mdc-tab-labels {
    border: 1.5px solid black;
    border-radius: 25px;
}

.overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 9000; /* Ensure it's above other content */
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #053671 !important;
  //margin-top: -8px !important;
}

.mdc-radio {
  margin-top: -8px !important;
}

.mb-5 {
  margin-bottom: 0rem !important;
}

th mat-checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: white !important;
  background-color: white !important;
}

td mat-checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #053671 !important;
  background-color: white !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  //border-color: #CC1100 !important;
  //background-color: #CC1100 !important; 
  border-color: darkgreen !important;
  background-color: darkgreen !important;
}

.table-container {
  height: 500px !important;
  overflow-y: hidden; 
}

.header-container {
  overflow: hidden;
  height: 45px; 
}

.body-container {
  max-height: calc(100% - 40px); 
  overflow-y: auto; 
}

.body-container::-webkit-scrollbar {
  width: 0.8em;
}

.body-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.body-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.no-data-message {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: black;
  height: 453px;
}

ng-http-loader .mat-spinner {
  width: 50px;
  height: 50px;
}

/* Change spinner color */
ng-http-loader .mat-spinner circle {
  stroke: #007bff; /* Replace with your desired color */
}

/* Center the spinner */
ng-http-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height if needed */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background */
  z-index: 99999 !important;
}

hr {
  margin: 0px 0px 15px 0px!important;
  border: none !important;
  border-top: 2.3px solid black !important; 
  opacity: 1.5 !important;
}

label {
  font-weight: 500;
}

.cncl-btn {
  border: 1.5px solid #39f !important;
  margin-right : 5px !important;
}

.mat-mdc-header-row {
  height: 45px !important;
  font-size: 15px !important;
}

.mdc-data-table__row {
  height: 35px !important;
}

/* Apply border to the mat-table */
.mat-mdc-table {
  border: 2px solid black !important;
}

/* Apply background color to the header row */
.mat-mdc-header-cell {
  background-color: #053671 !important;
  font-weight: bold !important; /* Optional: To make the header text bold */
  color: white !important;
}

.mat-mdc-row:hover {
  background-color: #afc8da !important; /* Change to your desired hover background color */
}

.edit-btn{
  color:orange !important;
}

.dlt-btn{
  color: red !important;
  margin-left: 3px;
}

html:not([dir=rtl]) .form-select {
  padding: 0.1rem 2.25rem 0.1rem 0.75rem !important;
}

.btn {
  font-size: 1.1rem !important;
  --cui-btn-font-weight: 400 !important;
  --cui-btn-line-height: 1.1 !important;
}

.color-blue {
  color:#3366CC !important;
}

.arrow-right {
  vertical-align: middle !important;
}

.font-size13{
  font-size: 13px !important;
}

.font-color{
    color: rgba(0, 0, 0, 0.87) !important;
}

.error-color{
    color:  red !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.nav-dropdown-item {
  padding-left: 10px; /* Default spacing for submenu items */
}

/* Toaster CSS start*/

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: relative;
  top: 0;
  right: 0;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #3c4b64;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/*Toaster CSS End*/

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #5584ff !important;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle{
    background-color: #5584ff !important;
}

span label {
  font-size: 1.3rem !important;
}

.crossBtn {
  font-size:16px !important;
  font-weight:bold !important;
}

@media all and (max-width: 400px) {
  .btn {
      font-size: 0.450rem !important;
  } 
  label {
    font-size: 0.5rem !important;
  }
  span label {
    font-size: 0.7rem !important;
  }
  .mat-mdc-header-cell {
    font-size: 0.6rem !important;
  }
  .font-size13 {
    font-size: 8px !important;
  }
  .material-icons {
    font-size: 16px !important;
  }
  .form-control-sm {
    font-size: 0.5rem !important;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    font-size: 8px !important;
  }
  .calendar-icon {
    font-size: 15px !important;
  }
  .avatar-img {
    width: 70% !important;
  }
  .crossBtn {
    font-size:11.5px !important;
  }  
}

@media all and (min-width: 401px) and (max-width: 550px) {
  .btn {
      font-size: 0.5rem !important;
  } 
  label {
    font-size: 0.6rem !important;
  }
  span label {
    font-size: 0.8rem !important;
  }
  .mat-mdc-header-cell {
    font-size: 0.7rem !important;
  }
  .font-size13 {
    font-size: 9px !important;
  }
  .material-icons {
    font-size: 18px !important;
  }
  .form-control-sm {
    font-size: 0.6rem !important;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    font-size: 9px !important;
  }
  .calendar-icon {
    font-size: 16px !important;
  }
  .avatar-img {
    width: 75% !important;
  }
  .crossBtn {
    font-size:12.5px !important;
  }  
}

@media all and (min-width: 551px) and (max-width: 850px) {
  .btn {
      font-size: 0.6rem !important;
  } 
  label {
    font-size: 0.6rem !important;
  }
  span label {
    font-size: 0.8rem !important;
  }
  .mat-mdc-header-cell {
    font-size: 0.7rem !important;
  }
  .font-size13 {
    font-size: 9px !important;
  }
  .material-icons {
    font-size: 18px !important;
  }
  .form-control-sm {
    font-size: 0.625rem !important;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    font-size: 10px !important;
  }
  .calendar-icon {
    font-size: 17px !important;
  }
  .avatar-img {
    width: 80% !important;
  }
  .crossBtn {
    font-size:13.5px !important;
  }  
}

@media all and (min-width: 851px) and (max-width: 1000px) {
  .btn {
      font-size: 0.7rem !important;
  }    
  label {
    font-size: 0.7rem !important;
  }
  span label {
    font-size: 0.9rem !important;
  }
  .mat-mdc-header-cell {
    font-size: 0.8rem !important;
  }
  .font-size13 {
    font-size: 10px !important;
  }
  .material-icons {
    font-size: 18px !important;
  }
  .form-control-sm {
    font-size: 0.675rem !important;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    font-size: 11px !important;
  }
  .calendar-icon {
    font-size: 18px !important;
  }
  .avatar-img {
    width: 85% !important;
  }
  .crossBtn {
    font-size:14.5px !important;
  }
}

@media all and (min-width: 1001px) and (max-width: 1224px) {
  .btn {
      font-size: 0.8rem !important;
  }
  label {
    font-size: 0.770rem !important;
  }
  span label {
    font-size: 0.970rem !important;
  }
  .mat-mdc-header-cell {
    font-size: 0.9rem !important;
  }
  .font-size13 {
    font-size: 11px !important;
  }
  .material-icons {
    font-size: 20px !important;
  }
  .form-control-sm {
    font-size: 0.7rem !important;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    font-size: 12px !important;
  }
  .calendar-icon {
    font-size: 19px !important;
  }
  .avatar-img {
    width: 90% !important;
  }
  .crossBtn {
    font-size:15.5px !important;
  }  
}

@media all and (min-width: 1225px) and (max-width: 1300px) {
  .btn {
      font-size: 0.9rem !important;
  } 
  label {
    font-size: 0.9rem !important;
  } 
  span label {
    font-size: 1.1rem !important;
  }
  .mat-mdc-header-cell {
    font-size: 0.8rem !important;
  }
  .font-size13 {
    font-size: 12px !important;
  }
  .material-icons {
    font-size: 22px !important;
  }
  .form-control-sm {
    font-size: 0.750rem !important;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    font-size: 13px !important;
  }
  .calendar-icon {
    font-size: 20px !important;
  }
  .avatar-img {
    width: 95% !important;
  }
  .crossBtn {
    font-size:15.5px !important;
  }
}

@media all and (min-width: 1301px) and (max-width: 1400px) {
  .btn {
      font-size: 1rem !important;
  }   
  label {
    font-size: 0.9rem !important;
  }
  span label {
    font-size: 1.3rem !important;
  }
  .crossBtn {
    font-size:15.5px !important;
  }
  .mat-mdc-header-cell {
    font-size: 0.8rem !important;
  }
}

@media all and (min-width: 1401px) {
  .mat-mdc-header-cell {
    font-size: 0.8rem !important;
  }
}


/*.form-control {
    padding: 5px !important;
}
.regular-font-size{
    font-size: 0.875rem !important;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.green-btn-setup{
    background-color: #5584ff;
    color: white;
    padding: 8px 18px;
    // margin-bottom: 6px;
}
.page-item.active .page-link {
    z-index: 1;
    color: #ffffff;
    background-color: #5584ff !important;
    border-color: #5584ff !important;
}
.color-green{
    color: #99abb4;
}
.page-content{
background: white !important;
}
.font-size15{
    font-size: 15px;
}

.padding-right5{
    padding-right: 5px;
}
.padding-right10{
    padding-right: 10px;
}
.padding-right15{
    padding-right: 15px;
}
.padding-left5{
    padding-left: 5px;
}
.padding-left10{
    padding-left: 10px;
}
.padding-left15{
    padding-left: 15px;
}
.padding-top5{
    padding-top: 10px;
}
.padding-top10{
    padding-top: 10px;
}
.padding-top15{
    padding-top: 15px;
}
.padding-bottom5{
    padding-bottom: 10px;
}
.padding-bottom10{
    padding-bottom: 10px;
}
.padding-bottom15{
    padding-bottom: 15px;
}
.clear-btn{
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #e4e6e8 !important;
}
th.mat-header-cell {
    background-color:#c2e0f3;
    font-weight:bold !important;
    border:1px solid #c8c8c8!important;
  }
  td.mat-cell{
    border:1px solid #c8c8c8!important;
  }
  .page-breadcrumb {
    display: none;
  }
  body {
    font-family: 'Roboto', sans-serif !important;
    background-color:#fff !important;
  }
  #snav .mat-nav-list .mat-list-item a {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500;
    font-size: 13px !important;
}
.mat-card .mat-card-content {
    font-size: 1rem;
    line-height: 23px;
    padding: 15px 24px !important;
}
.mat-tab-label .mat-tab-label-content {
    font-family: "Roboto", sans-serif !important;
    font-weight: 800;
    font-size: 16px;
}
.mat-header-cell {
    font-size: 14px;
    font-size: 14px;
    font-weight: 500;
    color: black;
    background:#f8f9fa;
    // font-family: 'Roboto', sans-serif;
  }
  label {
    display: inline-block;
    margin-bottom: 5px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
}

mat-checkbox > label {
  margin-bottom: 0px !important;
}

.cssModel > .modal-dialog {
    max-width: 319px !important;
    margin: 8.75rem auto 1.75rem auto !important;
  }

  .mat-radio-label-content {
    padding-left: 4px !important;
  }
  .mat-toolbar.topbar .form-control {
    border: 0px;
    margin-top: 5px;
    padding: 10px;
    line-height: initial;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgba(120, 130, 140, 0.13);
}
.btn {
    padding: 8px 18px !important;
}
 .mat-radio-button .mat-radio-ripple {
    position: absolute;
    left: calc(50% - 11px) !important;
    top: calc(50% - 11px) !important;  
    height: 22px !important;
    width: 22px !important;
    z-index: 1;
    pointer-events: none;
}
.mat-radio-container {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 16px !important;
    height: 16px !important;
    flex-shrink: 0;
}
 .mat-radio-outer-circle {
    box-sizing: border-box;
    display: block;
    height: 16px !important;
    left: 0;
    position: absolute;
    top: 0;
    transition: border-color ease 280ms;
    width: 16px !important;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
}
.mat-radio-inner-circle{
    height: 16px !important;
    width: 16px !important;
}
hr {
    background: rgb(2 2 2 / 13%) !important;
}

.modal-backdrop {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 10vw !important;
    height: 10vh !important;
    background-color: #000;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
   // right: -206px !important;
    width: 92% !important;
   // top: 67px !important;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.arrowArr{
    padding-right: 34px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// input[type=number] {
//   -moz-appearance: textfield;
// }

.ql-editor strong{
    font-weight:bold;
}

.mat-tab-label-content {
    color: black !important;
}

mat-cell {
    color: black !important;
}

td, .mat-sort-header-content
{
    color: black !important;
    // width: 44px !important;
}
.aaaa{
    box-shadow: 0px 5px 10px 0px !important;
}

.ql-tooltip, .ql-editing{
    left: 0px !important;
    top: 0px !important;
}

.modal-content{
    box-shadow: 2px 2px 9px 4px !important;
}

.color-gray{
    padding: 5px !important;
}
.ck.ck-tooltip {
    margin-left: 20px !important;
}

.dark .mat-tree-node, .dark .mat-nested-tree-node {
    color: black !important;
}
//sweetaleart2
::ng-deep.swal2-confirm{
    margin-right: 50px;
}
::ng-deep.swal2-confirm{
    margin-right: 50px !important;
    outline: none !important;
    border: none !important;
  }
  
  ::ng-deep.swal2-confirm .btn .btn-success{
    outline: none !important;
    border: none !important;
      &:hover::after,
      &:active::after,
      &:focus::after {
        outline: 0 !important;
        border: 0 !important;
      }
  }
  
  
  ::ng-deep .swal2-popup {
    font-size: 1rem !important;
    }
  
  ::ng-deep .swal2-icon{
   // font-size: 20px !important;
    width: 42px !important;
    height: 42px !important;
    left: 4px !important;
    right: 5px !important;
    top: 18px !important;
    margin-bottom: 20px !important;
  }
  
  ::ng-deep .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
    position: absolute;
    height: 4px!important;
    width: 30px!important;
    background-color: #f27474;
    display: block;
    top: 20px !important;
    border-radius: 2px;
  }
  
  ::ng-deep .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
   // -webkit-transform: rotate(45deg);
    //transform: rotate(45deg);
    left: 6px !important;
  }
  
  ::ng-deep .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
    // -webkit-transform: rotate(45deg);
     //transform: rotate(45deg);
     left: 6px !important;
   }
  
  ::ng-deep .swal2-title{
    font-size: 24px !important;
    // font-family: sans-serif!important;
     font-weight:400!important;
    // line-height: 36px;
    // color:rgb(84, 84, 84)!important;
  }
  ::ng-deep .swal2-html-container{
    font-size: 13px !important;
  }
  
::ng-deep.swal2-confirm{
  margin-right: 50px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

::ng-deep.swal2-confirm .btn .btn-success{
  outline: none !important;
  border: none !important;
  //box-shadow: none !important;
    &:hover::after,
    &:active::after,
    &:focus::after {
      outline: 0 !important;
      border: 0 !important;
     // box-shadow: none !important;
    }
}


::ng-deep .swal2-popup {
  font-size: 1rem !important;
  }

::ng-deep .swal2-icon{
 // font-size: 20px !important;
  width: 42px !important;
  height: 42px !important;
  left: 4px !important;
  right: 5px !important;
  top: 18px !important;
}

::ng-deep .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  position: absolute;
  height: 4px!important;
  width: 30px!important;
  background-color: #f27474;
  display: block;
  top: 20px !important;
  border-radius: 2px;
}

::ng-deep .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
 // -webkit-transform: rotate(45deg);
  //transform: rotate(45deg);
  left: 6px !important;
}

::ng-deep .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  // -webkit-transform: rotate(45deg);
   //transform: rotate(45deg);
   left: 6px !important;
 }

::ng-deep .swal2-title{
  font-size: 24px !important;
  // font-family: sans-serif!important;
   font-weight:400!important;
  // line-height: 36px;
  // color:rgb(84, 84, 84)!important;
}
::ng-deep .swal2-html-container{
  font-size: 13px !important;
}*/